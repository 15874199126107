import './App.css';
import React, {useContext} from "react";
import styled from "styled-components";
import {Navbar} from "twd-react-components";


const ScreenDiv = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`;

// Styling for the Box containing the whole app.
const AppDiv = styled.div`
    height: calc(100% - 50px);
    margin-top: 50px;
    background-color: var(--twd_grey);
    @media (max-width: 1250px) {
        overflow: auto;
    }
`;

function App() {
    
    return (
        <ScreenDiv>
            <Navbar title={"TWD Live"}></Navbar>
            <AppDiv>
                <div style={{position: "relative", paddingTop: "56.25%"}}>
                    <iframe
                        src="https://customer-08287rxg9f7f2bvu.cloudflarestream.com/078e2af7865765ab28a48e621d0248e6/iframe"
                        style={{
                            border: "none",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "87%",
                            width: "100%",
                        }}
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </AppDiv>
        </ScreenDiv>
    );
}


export default App;